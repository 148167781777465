




































































































import api from "@/api";
import {Component, Vue, Ref} from "vue-property-decorator";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {ExportButton, PagedTableView},
})
export default class ServiceOrganizationList extends Vue {
  @Ref() readonly pagedTableView!: any;
  editId = 0;
  queryForm: any = {
    starRatingTitle: undefined,
    typeId: undefined,
  };
  evaluationTypeList: DataDictionaryDto[] = [];
  exportFieldList=["主题","评定类型","负责人","报名人数","通过人数","主办方"]

  async fetchData(params: any) {
    // debugger;
    return await api.starRatingManagement.getAll(params);
  }

  created() {
    this.fetchEvaluationTypeList();
    this.fetchDictionary();
  }

  async fetchEvaluationTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: 'EvaluationType',
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.evaluationTypeList = res!.items!;
      });
  }

  getType(id: number) {
    if (this.evaluationTypeList.length > 0) {
      for (let i = 0; i < this.evaluationTypeList.length; i++) {
        if (this.evaluationTypeList[i].id == id) {
          return this.evaluationTypeList[i].displayName;
        }
      }
    }
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "summary-table-create",
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: any) {
    this.editId = row.id!;
    this.$router.push({
      name: "summary-table-create",
      params: {id: row.id!.toString(), isDetail: 'true'},
    });
  }

  // 跳转编辑页
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "summary-table-create",
      params: {id: row.id!.toString()},
    });
  }

  // 删除
  handleDelete(item: number, row: any) {
    this.$confirm("您确定删除该星级评定总结表吗?", "提示").then(() => {
      api.starRatingManagement.delete({id: row.id}).then((res) => {
        this.$message.success("删除成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
