






































































































import api from "@/api"; //ABP API接口
import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {DataDictionaryDtoPagedResultDto, DataDictionaryDto, StarRatingService} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import {getStarRatingStatusList, getTrainStatusList} from "@/utils/enumToSelectItem";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "StarRatingList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    ExportButton
  }
})
export default class StarRatingList extends Vue {
  statusList = getStarRatingStatusList();
  typeList: DataDictionaryDto[] = [];
  queryForm: any = {
    title: undefined,
    typeId: undefined,
    creationTime: undefined,
  }
  visible = false;
  selectId = 0;
  exportFieldList=["主题","评定类型","负责人","创建时间","公示时间"]
  closePublicityTime=""

  created() {
    this.fetchDataDictionary()
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.starRating.getAll(params);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "EvaluationType", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  //发布新的星级评定
  handleCreate() {
    this.$router.push({name: 'star-level-evaluate-publish'})
  }


  // 评定记录
  handleRecord(item: any) {
    this.$router.push({name: 'recordAndPublicity-record', params: {'id': item.id!.toString()}})
  }

  // 考题管理
  handleExamList(item: any) {
    this.$router.push({name: 'examination', params: {'id': item.id!.toString()}})
  }

  // 报名管理
  handleRegistration(item: any) {
    this.$router.push({name: 'registration', params: {'id': item.id!.toString()}})
  }

  // 跳转编辑页
  handleEdit(item: any) {
    this.$router.push({name: 'star-level-evaluate-publish', params: {'id': item.id!.toString()}})
  }

  //删除
  handleDelete(item: any) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.starRating.delete({id: item.id}).then(res => {
        this.$message.success('删除成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }

  //公示
  handlePublicity() {
    if (!this.closePublicityTime){
      this.$message.error('请选择关闭公示时间');
    }
    this.$confirm('确定开始公示吗?', '提示').then(() => {
      api.starRating.publicity({body: {id: this.selectId,closePublicityTime:this.closePublicityTime as any}}).then(res => {
        this.$message.success('公示成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
  //公示
  handlePublicityShow(item:any) {
    this.visible=true
    this.selectId = item.id;
    this.closePublicityTime=""
  }

  // 取消公示
  handleUnPublicity(item: any) {
    let confirmMessage = '确定关闭公示吗?';
    this.$confirm(confirmMessage, '提示').then(() => {
      api.starRating.unPublicity({body: {id: item.id}}).then(res => {
        this.$message.success('关闭公示成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
}
